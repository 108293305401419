import React from 'react'
import { IntlProvider } from 'react-intl'

import english from './data/en-US'


const locales = {
  'en-US': english,
}



const Localization = ({ children}) => {
  const currentLocale = locales["en-US"]
  return (
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
  )
}

export default Localization
