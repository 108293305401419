import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { connect } from "react-redux";
import Layout from "./layouts";
import ACL from "./components/ACL";

const routes = [
  {
    path: "/search",
    Component: lazy(() => import("./pages/dashboard/search")),
    exact: true,
  },
  {
    path: "/user",
    Component: lazy(() => import("./pages/dashboard/user")),
    exact: true,
  },
  {
    path: "/auth/logout",
    Component: lazy(() => import("./pages/auth/logout")),
    exact: true,
  },
  {
    path: "/auth/login",
    Component: lazy(() => import("./pages/auth/login")),
    exact: true,
  },
  {
    path: "/auth/404",
    Component: lazy(() => import("./pages/auth/404")),
  },
];


const mapStateToProps = ({ settings }) => ({
})

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition key={location.pathname} appear timeout={300}>
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/create-user-role" />}
                    />
                    {routes.map(
                      ({ path, Component, exact, roles, redirect }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div>
                                <Suspense fallback={null}>
                                  {roles === undefined ? (
                                    <Component />
                                  ) : (
                                    <ACL roles={roles} redirect={redirect}>
                                      <Component />
                                    </ACL>
                                  )}
                                </Suspense>
                              </div>
                            );
                          }}
                        />
                      ),
                    )}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router)
