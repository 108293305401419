import { all, takeEvery, put, call } from "redux-saga/effects";
import {
  getAllRoles,
  getUserRole,
  updateUserRole,
  createUserRole,
} from "../../services/roles";
import { getOpenId } from "../../services/user";
import store from "store";
import actions from "./actions";

export function* GET_ALL_ROLES({ payload }) {
  yield put({
    type: "roles/SET_STATE",
    payload: {
      loading: true,
    },
  });

  const authGuid = store.get("app.settings.id");
  const response = yield call(getAllRoles, authGuid);
  if (response) {
    const userRoles = response?.filter((role) => !role.name.includes("NTF"));
    const notificationRoles = response?.filter((role) =>
      role.name.includes("NTF"),
    );
    return yield put({
      type: "roles/SET_STATE",
      payload: {
        postError: "",
        loading: false,
        userRoles,
        notificationRoles,
      },
    });
  }

  return yield put({
    type: "roles/SET_STATE",
    payload: {
      postError: "There is an error fetching roles",
      loading: false,
    },
  });
}

export function* CREATE_USER_ROLE({ payload }) {
  const { email, userRole, notificationRole } = payload;

  const openIdResponse = yield call(getOpenId, email);
  if (!openIdResponse || openIdResponse.resultCount === 0) {
    return yield put({
      type: "roles/SET_STATE",
      payload: {
        postError: "There is an error fetching user info",
        loading: false,
      },
    });
  }
  const { result } = openIdResponse;
  const { GUID: userGuid } = result[0];

  const authGuid = store.get("app.settings.id");
  const response = yield call(
    createUserRole,
    {
      guid: userGuid,
      roles: [userRole, notificationRole],
    },
    authGuid,
  );

  if (!response || response.error) {
    return yield put({
      type: "roles/SET_STATE",
      payload: {
        postError: response?.message || "There is an error creating user roles",
        loading: false,
      },
    });
  }

  return yield put({
    type: "roles/SET_STATE",
    payload: {
      postSuccess: "Create successful.",
      postError: "",
      loading: false,
    },
  });
}

export function* UPDATE_USER_ROLE({ payload }) {
  const { userGuid, notificationRole, userRole } = payload;
  const authGuid = store.get("app.settings.id");
  const response = yield call(
    updateUserRole,
    {
      guid: userGuid,
      roles: [userRole, notificationRole],
    },
    authGuid,
  );

  if (!response || response.error) {
    return yield put({
      type: "roles/SET_STATE",
      payload: {
        updateError:
          response?.message || "There is an error updating user roles.",
        loading: false,
      },
    });
  }

  return yield put({
    type: "roles/SET_STATE",
    payload: {
      updateSuccess: "Update successful.",
      updateError: "",
      loading: false,
    },
  });
}

export function* GET_USER_ROLE({ payload }) {
  yield put({
    type: "roles/SET_STATE",
    payload: {
      currentUserEmail: "",
      loading: true,
    },
  });

  const { email } = payload;

  const openIdResponse = yield call(getOpenId, email);

  if (!openIdResponse || openIdResponse.resultCount === 0) {
    return yield put({
      type: "roles/SET_STATE",
      payload: {
        searchError: "Error: User Not Found",
        loading: false,
      },
    });
  }

  const { result } = openIdResponse;
  const { GUID: userGuid } = result[0];
  const authGuid = store.get("app.settings.id");
  const response = yield call(getUserRole, userGuid, authGuid);

  if (!response) {
    return yield put({
      type: "roles/SET_STATE",
      payload: {
        searchError: "There is an error getting user roles",
        loading: false,
      },
    });
  }

  const { guid, roles } = response;
  const currentUserRole = roles
    .filter((role) => !role.includes("NTF"))
    .join("");
  const currentNotificationRole = roles
    .filter((role) => role.includes("NTF"))
    .join("");

  return yield put({
    type: "roles/SET_STATE",
    payload: {
      currentUserEmail: email,
      currentUserRole,
      currentNotificationRole,
      currentUserId: guid,
      searchError: "",
      loading: false,
    },
  });
}

export function* CLEAR_PROMPT() {
  yield put({
    type: "roles/SET_STATE",
    payload: {
      searchError: "",
      postError: "",
      updateError: "",
      postSuccess: "",
      updateSuccess: "",
      loading: false,
    },
  });
}

export function* CLEAR_SEARCH() {
  yield put({
    type: "roles/SET_STATE",
    payload: {
      currentUserEmail: "",
      currentUserId: "",
      currentUserRole: "",
      currentNotificationRole: "",
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_ROLES, GET_ALL_ROLES),
    takeEvery(actions.GET_USER_ROLE, GET_USER_ROLE),
    takeEvery(actions.UPDATE_USER_ROLE, UPDATE_USER_ROLE),
    takeEvery(actions.CREATE_USER_ROLE, CREATE_USER_ROLE),
    takeEvery(actions.CLEAR_PROMPT, CLEAR_PROMPT),
    takeEvery(actions.CLEAR_SEARCH, CLEAR_SEARCH),
    // run once on app load to check user auth
  ]);
}
