import { all } from 'redux-saga/effects'
import user from './user/sagas'
import roles from './roles/sagas'


export default function* rootSaga() {
  yield all([
    user(),
    roles()
  ])
}
