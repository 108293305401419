import { apiClient, fetchClient } from "../axios";
import { store as reduxStore } from "../../index";
import store from "store";

export async function createUserRole(userRole, authGuid) {
  return apiClient
    .post(
      "/users",
      {
        ...userRole,
      },
      {
        headers: {
          "X-GUID": authGuid,
        },
      },
    )
    .then((response) => {
      if (response) {
        return response;
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: true,
        message: err?.message || "There is an error creating user roles",
      };
    });
}

export async function updateUserRole(userRole, authGuid) {  
  return apiClient
    .put(
      "/users",
      {
        ...userRole,
      },
      {
        headers: {
          "X-GUID": authGuid,
        },
      },
    )
    .then((response) => {
      if (response) {
        return response;
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: true,
        message: err?.message || "There is an error updating user roles",
      };
    });
}

/*
  GET request are using fetch api because that axios doesn't allow 
  'Content-Type': 'application/json'
  being put on the headers.
*/
export async function getUserRole(userGuid, authGuid) {
  const accessToken = store.get("accesstoken");
  return fetchClient(`/users/${userGuid}`, authGuid, accessToken)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        return result;
      }
      return false;
    })
    .catch((error) => {
      return handleError(error);
    });
}

export async function getAllRoles(authGuid) {
  const accessToken = store.get("accesstoken");
  return fetchClient("/roles", authGuid, accessToken)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .then((result) => {
      if (result) {
        return result;
      }
      return false;
    })
    .catch((error) => {
      return handleError(error);
    });
}

const handleError = (error) => {
  if (error.toString().includes("401") || error.toString().includes("403")) {
    return reduxStore.dispatch({
      type: "user/LOGOUT",
    });
  }
  return false;
};
