import React, { createContext, useState } from "react";
import { nanoid } from "nanoid";
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../index";
import { cacheHeaders } from "../config";
const logs = "user/logs";
const qaPath = "https://tmca-gtpportalapi.qa.telematicsct.com.au/"

export const LogsContext = createContext({
  notes: [{ text: "Hello, i'm default", id: "0" }],
});

export const LogsProvider = ({ children }) => {
  // let history = useHistory();
  const [list, setList] = useState([]);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logsList, setLogsList] =  useState()
  const [allList, setAllList] = useState([]);

  const [searchFields, setSearchFields] = useState({
    guid: "",
    logSource: "One",
  });

  const getLogs = (ev) => {
    setLoading(true)
    fetch(qaPath+logs, {
      headers: {
        "guid": searchFields.guid,
        "logSource": searchFields.logSource,
        ...cacheHeaders
      },
      method: "get",
    })
    .then((res) =>
      res.ok ? res.json() : Promise.reject(new Error(res.status.toString()))
    )
    .then((result) => {
      // setData(result)
      console.log(result.logs)
      console.log(result.nextPage)
      console.log(result.results)
      setLogsList(result)
      setLoading(false)
      history.push('/user')
      result.logs && result.nextPage && result.results && setData(result)
      result.logs && result.nextPage && result.results && setLogsList(result)
      // result.logs && result.nextPage && result.results && history.push('/user')
      // logsList &&
      // 504.message
    })
    .catch((err) => {
      setLoading(false)
      console.log(err)
    });
  };

  const getLogsNextPage = (ev) => {
    setLoading(true)
    fetch(qaPath+logs, {
      headers: {
        "guid": searchFields.guid,
        "logSource": searchFields.logSource,
        "nextPage": logsList.nextPage,
        ...cacheHeaders
      },
      method: "get",
    })
    .then((res) =>
      res.ok ? res.json() : Promise.reject(new Error(res.status.toString()))
    )
    .then((result) => {
      // let total = [];
      // logsList.logs.push(result?.logs)
      console.log(logsList)
      result.logs.map(item => {
        return logsList.logs.push(item)
      })
      console.log(logsList)
      setLogsList(...logsList, {logs: result.logs})
      setLoading(false)
      console.log(logsList)
    })
    .catch((err) => {
      setLoading(false)
      console.log(err)
    });
  };

  return (
    <LogsContext.Provider value={{
      loading,
      data, setData,
      setLoading,
      logsList,
      setLogsList,
      searchFields,
      setSearchFields,
      getLogs,
      getLogsNextPage,
      allList,
      setAllList
       }}>
      {children}
    </LogsContext.Provider>
  );
};