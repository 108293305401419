import { all, takeEvery, put, call } from "redux-saga/effects";
import { history } from "../../index";
import { login, getOpenId } from "../../services/user";
import store from "store";
import actions from "./actions";

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  const response = yield call(login, { username: email, password });
  if (response && response.access_token) {
    store.set("accesstoken", response.access_token);
    const loginDetails = {
      email: email,
      authorized: true,
    };

    Object.keys(loginDetails).forEach((key) => {
      store.set(`app.settings.${key}`, loginDetails[key]);
    });

    yield put({
      type: "user/SET_STATE",
      payload: {
        ...loginDetails,
      },
    });
    yield history.push("/search");
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loginError: "Login Error",
      },
    });
  }

  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
    },
  });
}

export function* LOGOUT() {
  const payload = {
    id: "",
    email: "",
    loginError: "",
    authorized: false,
    loading: false,
  };
  Object.keys(payload).forEach((key) => {
    store.set(`app.settings.${key}`, payload[key]);
  });
  store.remove("accesstoken");
  yield put({
    type: "user/SET_STATE",
    payload,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
  ]);
}
