import { authClient, openIdClient } from "../axios";
import qs from "qs";

export async function login(credentials) {
  return authClient
    .post(
      "",
      qs.stringify({
        ...credentials,
        client_id: "oneportal",
        client_secret: "oneportal",
        grant_type: "password",
      }),
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getOpenId(email) {
  return (
    openIdClient
      .get(`?_queryFilter=userName+eq+"${email}"`)
      .then((response) => {
        if (response) {
          return response.data;;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
      })
  );
}
