import axios from "axios";
import store from "store";
import { store as reduxStore } from "../../index";
import { SERVER_PATH, AUTH_PATH, API_KEY, OPEN_ID_PATH } from "../../data/api";

const authClient = axios.create({
  baseURL: AUTH_PATH,
  timeout: 3000,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});

const openIdClient = axios.create({
  baseURL: OPEN_ID_PATH,
  timeout: 3000,
  headers: {
    "X-OpenIDM-Username": "idmadmin",
    "X-OpenIDM-Password": "ttsju0lHfxwMxgkf9p",
  },
});

const apiClient = axios.create({
  baseURL: SERVER_PATH,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": API_KEY,
  },
});

const fetchClient = (link, authGuid, accessToken) =>
  fetch(`${SERVER_PATH}${link}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "X-GUID": authGuid,
      "x-api-key": API_KEY,
      Authorization: `Bearer ${accessToken}`,
    },
  });

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get("accesstoken");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});

apiClient.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403 || error?.response?.status === 401) {
    reduxStore.dispatch({
      type: "user/LOGOUT",
    });
  } else {
    throw new Error(error.response?.data?.status?.messages[0]?.description);
  }
});

export { authClient, apiClient, openIdClient, fetchClient };
