import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// import accounts from './accounts/reducers'
import user from "./user/reducers";
import roles from './roles/reducers'
// import settings from './settings/reducers'

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    roles,
  });

  export default reducers;
