const actions = {
  SET_STATE: "roles/SET_STATE",
  GET_ALL_ROLES: "roles/GET_ALL_ROLES",
  GET_USER_ROLE: "roles/GET_USER_ROLE",
  UPDATE_USER_ROLE: "roles/UPDATE_USER_ROLE",
  CREATE_USER_ROLE: "roles/CREATE_USER_ROLE",
  CLEAR_PROMPT: "roles/CLEAR_PROMPT",
  CLEAR_SEARCH: "roles/CLEAR_SEARCH"
};

export default actions;
