import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { history } from "../../index";

const mapStateToProps = ({ user }) => ({ user });

const ACL = ({
  user: { role },
  redirect = false,
  defaultRedirect = "/auth/404",
  roles = [],
  children,
}) => {
  return <Fragment>{children}</Fragment>;
};

export default connect(mapStateToProps)(ACL);
