import actions from "./actions";

const initialState = {
  userRoles: [],
  notificationRoles: [],
  
  currentUserEmail:"",
  currentUserId: "",
  currentUserRole: "",
  currentNotificationRole: "",

  searchError: "",
  postError: "",
  updateError: "",
  postSuccess: "",
  updateSuccess: "",
  loading: false,
};

export default function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
