import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createHashHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
// import StylesLoader from "./stylesLoader";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import Localization from "./localization";
import Router from "./router";
import reportWebVitals from "./reportWebVitals";
import { LogsProvider } from "./context/LogsContext";

// middlewared
const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}
const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <LogsProvider>
      {/* <StylesLoader> */}
        <Localization>
          <Router history={history} />
        </Localization>
      {/* </StylesLoader> */}
    </LogsProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { store, history };
